import { useTranslate } from 'react-polyglot'

import { useAlertContext } from '@unlikelystudio/react-abstract-components'

import { ALERT } from '~/data/dictionary'

export default function useOnMutationSuccess() {
  const t = useTranslate()
  const { triggerAlert } = useAlertContext()

  return (alertKey: keyof typeof ALERT) => {
    triggerAlert({
      title: t(ALERT.SUCCESS_TITLE),
      message: t(ALERT?.[alertKey]),
    })
  }
}
