import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { sfCustomer } from '@unlikelystudio/commerce-connector'
import { LoginForm } from '@unlikelystudio/react-ecommerce-hooks'

import RichText from '~/components/Abstracts/RichText'
import LayoutUnLogged from '~/components/Account/Unlogged/Layout'
import CtaWithPrice from '~/components/CtaWithPrice'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import { useRedirectMultipassCheckoutUrl } from '~/hooks/checkout/useGetMultipassCheckoutUrl'

import { ACCOUNT } from '~/data/dictionary'

import css from './styles.module.scss'
import { LayoutLoginProps, LoginProps } from './types'

const cx = classnames.bind(css)

function Login({ className, registerTitle, registerDescription }: LoginProps) {
  const t = useTranslate()
  const router = useRouter()
  const tracker = useTracker()
  const handleError = useOnMutationError()
  const { mutate: handleCartConfirmation } = useRedirectMultipassCheckoutUrl()
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title26Primary,
    color: GlobalThemeColors.Black,
  })

  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text18Secondary,
    color: GlobalThemeColors.Black,
  })

  const { query } = useRouter()

  const checkoutUrl = query.checkout_url

  return (
    <div className={cx(css.Login, className)}>
      <div className={css.loginContainer}>
        <LoginForm
          mutationOptions={{
            onError: handleError,
            onSuccess: (data) => {
              const { storefrontAccessToken, storeUrl } =
                getStorePublicCredentials()

              sfCustomer(
                { storefrontAccessToken, storeUrl },
                {
                  token: data.customerAccessToken.accessToken,
                },
              ).then((response) => {
                const customer = response?.customer

                if (!customer) {
                  tracker.emit(TRACKING_EVENTS.LOGIN)
                  return null
                }

                tracker.emit(TRACKING_EVENTS.LOGIN, {
                  email: customer.email,
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                })
              })

              if (checkoutUrl) {
                handleCartConfirmation({
                  url: Array.isArray(checkoutUrl)
                    ? checkoutUrl[0]
                    : checkoutUrl,
                  accessToken: data?.customerAccessToken.accessToken,
                })
              } else {
                router.push('/account')
              }
            },
          }}
        />

        <InlineCta
          className={css.button}
          textStyling={GlobalTextStyling.UpperCase}
          theme={GlobalThemeColors.Black}
          textPreset={GlobalTextPreset.Cta10Primary}
          onClick={() => router.push(`/forgot-password`)}>
          {t(ACCOUNT.FORGOT_PASSWORD)}
        </InlineCta>
      </div>

      <div className={css.registerContainer}>
        {registerTitle && (
          <h1 className={cx(css.title, titleStyle)}>{registerTitle}</h1>
        )}

        {registerDescription && (
          <RichText
            className={cx(css.description, descriptionStyle)}
            render={registerDescription}
          />
        )}

        <CtaWithPrice
          className={css.button}
          onClick={() => router.push(`/register`)}
          theme={GlobalThemeColors.Black}
          price={null}>
          {t(ACCOUNT.REGISTER)}
        </CtaWithPrice>
      </div>
    </div>
  )
}

Login.defaultProps = {
  redirectOnSuccess: true,
}

export function LayoutLogin({
  backgroundImage,
  title,
  description,
  ...rest
}: LayoutLoginProps) {
  return (
    <LayoutUnLogged
      backgroundImage={backgroundImage}
      title={title}
      description={description}>
      <Login {...rest} />
    </LayoutUnLogged>
  )
}

export default Login
