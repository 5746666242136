import LoadingPage from '~/components/Abstracts/LoadingPage'

import useAuthentication from '~/hooks/account/useAuthentication'

export default function withoutAuthentication(WrappedComponent) {
  const HOC = (pageProps) => {
    const { isInitialLoading, data: customer } = useAuthentication(
      null,
      '/account',
    )

    return isInitialLoading || customer ? (
      <LoadingPage />
    ) : (
      <WrappedComponent {...pageProps} />
    )
  }

  return HOC
}
