import classnames from 'classnames/bind'
import { useRouter } from 'next/router'

import { RequestResetPasswordForm } from '@unlikelystudio/react-ecommerce-hooks'

import { LayoutForgotPasswordProps } from '~/components/Account/Unlogged/ForgotPassword/types'
import LayoutUnLogged from '~/components/Account/Unlogged/Layout'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useOnMutationSuccess from '~/hooks/account/useOnMutationSuccess'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function ForgotPassword({ className }: any) {
  const router = useRouter()
  const handleError = useOnMutationError()
  const handleSuccess = useOnMutationSuccess()

  return (
    <div className={cx(css.ForgotPassword, className)}>
      <RequestResetPasswordForm
        mutationOptions={{
          onSuccess: () => {
            handleSuccess('RESET_PASSWORD_EMAIL_SENT')
            router.push('/login')
          },
          onError: handleError,
        }}
      />
    </div>
  )
}

ForgotPassword.defaultProps = {
  redirectOnSuccess: true,
}

export function LayoutForgotPassword({
  backgroundImage,
  title,
  description,
  ...rest
}: LayoutForgotPasswordProps) {
  return (
    <LayoutUnLogged
      backgroundImage={backgroundImage}
      title={title}
      description={description}>
      <ForgotPassword {...rest} />
    </LayoutUnLogged>
  )
}

export default ForgotPassword
