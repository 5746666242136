import { useTranslate } from 'react-polyglot'

import { StorefrontError } from '@unlikelystudio/commerce-connector'
import { useAlertContext } from '@unlikelystudio/react-abstract-components'
import { formDictionary } from '@unlikelystudio/react-ecommerce-hooks'

import useLocale from '~/hooks/useLocale'

import { ALERT } from '~/data/dictionary'

export default function useOnMutationError() {
  const locale = useLocale()
  const t = useTranslate()
  const { triggerAlert } = useAlertContext()
  return (error: StorefrontError) => {
    if (error.response.status === 200) {
      // This is probably a displayable error you can find more details in the response objects.
      const code = error.response.errors[0]?.code
      if (code) {
        triggerAlert({
          title: t(ALERT.ERROR_TITLE),
          message: formDictionary[locale][code],
        })
      } else {
        const message = error.response.errors[0]?.message

        if (message) {
          triggerAlert({
            title: t(ALERT.ERROR_TITLE),
            message,
          })
        }
      }
    }
  }
}
