import classnames from 'classnames/bind'
import { useRouter } from 'next/router'

import {
  ActivateCustomerForm,
  ResetPasswordForm,
} from '@unlikelystudio/react-ecommerce-hooks'

import LayoutUnLogged from '~/components/Account/Unlogged/Layout'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useOnMutationSuccess from '~/hooks/account/useOnMutationSuccess'
import useShopifyUpdateCustomerLocale from '~/hooks/account/useShopifyUpdateCustomerLocale'

import css from './styles.module.scss'
import { LayoutResetPasswordProps, ResetPasswordProps } from './types'

const cx = classnames.bind(css)

function ResetPassword({ className, enableAccount }: ResetPasswordProps) {
  const router = useRouter()

  const handleError = useOnMutationError()
  const handleSuccess = useOnMutationSuccess()

  const { mutate: mutateCustomerLocale } = useShopifyUpdateCustomerLocale()

  const onEnableCustomerSuccess = (data: any) => {
    const id = data?.customerActivateByUrl?.customer?.id
    mutateCustomerLocale({ id })
    router.push('/login')
  }

  return (
    <div className={cx(css.ResetPassword, className)}>
      {enableAccount ? (
        <ActivateCustomerForm
          mutationOptions={{
            onError: handleError,
            onSuccess: onEnableCustomerSuccess,
          }}
        />
      ) : (
        <ResetPasswordForm
          mutationOptions={{
            onError: handleError,
            onSuccess: () => {
              handleSuccess('ENTITY_UPDATED')
              router.push('/login')
            },
          }}
        />
      )}
    </div>
  )
}

ResetPassword.defaultProps = {
  redirectOnSuccess: true,
}

export function LayoutResetPassword({
  backgroundImage,
  title,
  description,
  ...rest
}: LayoutResetPasswordProps) {
  return (
    <LayoutUnLogged
      backgroundImage={backgroundImage}
      title={title}
      description={description}>
      <ResetPassword {...rest} />
    </LayoutUnLogged>
  )
}

export default ResetPassword
