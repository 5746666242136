import classnames from 'classnames'
import React, { ReactNode } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'
import FormTemplateProvider from '~/components/forms-templates/FormTemplateProvider'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AccountUnloggedWrapperProps {
  className?: string
  backgroundImage?: ImageProps
  title: string
  description?: RichTextBlock
  children?: ReactNode | ReactNode[]
}

export default function AccountUnloggedWrapper({
  children,
  className,
  backgroundImage,
  title,
  description,
}: AccountUnloggedWrapperProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title26Primary,
    color: GlobalThemeColors.Black,
  })

  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text18Secondary,
    color: GlobalThemeColors.Black,
  })

  const formStyle = useStyle({
    formsPreset: 'form',
  })

  return (
    <FormTemplateProvider>
      <div className={cx(className, css.AccountUnloggedWrapper)}>
        <Image
          layout="fill"
          objectFit="cover"
          priority
          className={css.backgroundImage}
          {...backgroundImage}
        />

        <div className={css.formWrapper}>
          {title && <h1 className={cx(css.title, titleStyle)}>{title}</h1>}

          {description && (
            <RichText
              className={cx(css.description, descriptionStyle)}
              render={description}
            />
          )}

          <div className={cx(css.children, formStyle)}>{children}</div>
        </div>
      </div>
    </FormTemplateProvider>
  )
}
