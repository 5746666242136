import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { TRACKING_EVENTS } from '~/lib/constants'

import { RegisterForm } from '@unlikelystudio/react-ecommerce-hooks'

import LayoutUnLogged from '~/components/Account/Unlogged/Layout'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useOnMutationSuccess from '~/hooks/account/useOnMutationSuccess'

import { ERROR } from '~/data/dictionary'

import css from './styles.module.scss'
import { LayoutRegisterProps, RegisterProps } from './types'

const cx = classnames.bind(css)

function Register({ className }: RegisterProps) {
  const t = useTranslate()
  const [error, setError] = useState(null)

  const router = useRouter()

  const handleError = useOnMutationError()
  const handleSuccess = useOnMutationSuccess()
  const tracker = useTracker()

  const errorStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Secondary,
    color: GlobalThemeColors.ErrorCompareAtPrice,
  })

  return (
    <div className={cx(css.Register, className)}>
      <RegisterForm
        showDetailedActivateAccountError
        loginMutationOptions={{
          onError: handleError,
          onSuccess: () => {
            handleSuccess('ACCOUNT_CREATED')
            setError(null)
            tracker.emit(TRACKING_EVENTS.SIGN_UP)
            router.push('/account')
          },
        }}
        mutationOptions={{
          onError: (error) => {
            handleError(error)
            setError(error)
          },
        }}
        privacyPolicyLink={'#'}
      />
    </div>
  )
}

Register.defaultProps = {
  redirectOnSuccess: true,
}

export default Register

export function LayoutRegister({
  backgroundImage,
  title,
  description,
  ...rest
}: LayoutRegisterProps) {
  return (
    <LayoutUnLogged
      backgroundImage={backgroundImage}
      title={title}
      description={description}>
      <Register {...rest} />
    </LayoutUnLogged>
  )
}
