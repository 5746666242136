import { LayoutForgotPassword } from '~/components/Account/Unlogged/ForgotPassword'
import { LayoutLogin } from '~/components/Account/Unlogged/Login'
import { LayoutRegister } from '~/components/Account/Unlogged/Register'
import { LayoutResetPassword } from '~/components/Account/Unlogged/ResetPassword'

import withoutAuthentication from '~/hocs/withoutAuthentication'

export const WithoutAuthenticationLogin = withoutAuthentication(LayoutLogin)

export const WithoutAuthenticationRegister =
  withoutAuthentication(LayoutRegister)

export const WithoutAuthenticationForgotPassword =
  withoutAuthentication(LayoutForgotPassword)

export const WithoutAuthenticationResetPassword =
  withoutAuthentication(LayoutResetPassword)
