import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { QueryMethods, requestNextApiEndpoint } from '~/lib/handle-api'

import useCurrency from '~/hooks/useCurrency'
import useLang from '~/hooks/useLang'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useShopifyUpdateCustomerLocale(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)

  const currency = useCurrency()
  const lang = useLang()

  return useMutation<any, any, any>(
    async ({ id }) => {
      return await requestNextApiEndpoint(
        {
          action: 'customers/update-locale',
          method: QueryMethods.PUT,
        },
        {
          store: currency,
          payload: {
            id,
            lang,
          },
        },
      )
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        if (mutationOptions?.onSuccess) mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        console.log(err)
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
