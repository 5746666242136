import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { Nullish } from '~/@types/generic'
import { getMultipass } from '~/lib/handle-api'

import {
  useCustomerContext,
  useGetCustomer,
} from '@unlikelystudio/react-ecommerce-hooks'

import { useGetCart } from '~/hooks/useGetCart'
import useLang from '~/hooks/useLang'
import useLocale from '~/hooks/useLocale'
import useOnMutationError from '~/hooks/useOnMutationError'

export interface getMultipassCheckoutUrlResponse {
  errors: { message: string }[]
  urlToRedirect: string
}

export function useGetMultipassCheckoutUrl(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const { data: customer } = useGetCustomer()
  const { customerAccessToken } = useCustomerContext()
  const onMutationError = useOnMutationError(setError)
  const lang = useLang()
  const locale = useLocale()
  const { rawCheckout: data } = useGetCart()
  const checkoutWebUrl = data?.checkoutUrl ?? null

  return useMutation<getMultipassCheckoutUrlResponse>(
    async () => {
      if (!customer?.email || !customerAccessToken)
        return (window.location.href = checkoutWebUrl)

      return await getMultipass({
        accessToken: customerAccessToken,
        payload: {
          url: checkoutWebUrl,
          locale,
          params: {
            locale: lang,
          },
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (data?.urlToRedirect && customerAccessToken) {
          const url = data?.urlToRedirect
          window.location.href = url
        }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}

export function useRedirectMultipassCheckoutUrl(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const lang = useLang()
  const locale = useLocale()
  const { rawCheckout: data } = useGetCart()
  const checkoutWebUrl = data?.checkoutUrl ?? null

  return useMutation<
    getMultipassCheckoutUrlResponse,
    unknown,
    { url: Nullish<string>; accessToken: Nullish<string> }
  >(
    async ({ url, accessToken }) => {
      if (!url || !accessToken) return (window.location.href = checkoutWebUrl)

      return await getMultipass({
        accessToken,
        payload: {
          url,
          locale,
          params: {
            locale: lang,
          },
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (data?.urlToRedirect) {
          const url = data?.urlToRedirect
          window.location.href = url
        }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
